@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');

@import "accounts/scss/providers";

button {
    @extend .btn;
    &:not([class*=btn-]) {
        @extend .btn-primary;
    }
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

nav.navbar {
    .dropdown-item:visited {
        color: inherit;
    }
    .dropdown-menu {
        max-height: 22em;
        overflow-y: scroll;
    }
}

footer {
    @extend .container-fluid, .mt-auto, .pt-3, .row;
	text-align: center;
	width: 100%;
	font-size: smaller;

    background-color: #f5f5f5;
    box-shadow: inset 0 8px 12px -12px black;

    // Bootstrap row includes negative margins on both sides which we don't want
    margin-left: 0;
    margin-right: 0;
}

.login-form-1 {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px 10px 0 0;
}

.login-form-2 {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    margin-left: -1px;
    border-radius: 0 0 10px 10px;
}

@media (min-width: 768px) {
    .login-form-1 {
        border-radius: 10px 0 0 10px;
    }

    .login-form-2 {
        border-radius: 0 10px 10px 0;
    }
}

.form-check > small {
    float: right;
}

ul.errorlist {
	margin: 0 0 4px;
	padding: 0;
	color: #ba2121;
	background: #fff;
}

ul.errorlist li {
	font-size: 13px;
	display: block;
	margin-bottom: 4px;
}

ul.errorlist li:first-child {
	margin-top: 0;
}

ul.errorlist li a {
	color: inherit;
	text-decoration: underline;
}

a.episode-link {
	font-size: x-large;
}

div.tile-list {
	align-content: center;
	align-items: center;
	display: flex;
	flex-flow: row wrap;
}

div.tile {
	height: 15em;
	flex: 0 0 15em;
	margin: 1em;
	text-align: center;
}

div.tile-available {
	border: 0.5em solid #222;
	background: DarkSlateGrey;
    position: relative;
}

div.tile-done {
	border: 0.5em solid #555;
	background-color: SlateGrey;
	position: relative;
}

div.tile-content {
    display: inline;
	margin: auto;
    color: White;

    > a {
		color: White;
		font-size: x-large;
		text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
	}

    p:last-child {
        position: absolute;
        bottom: 0;
    }
}

div.tile-event {
    border-width: 1em;
    border-style: double;
    padding: 1em;
}

h1 {
	font-size: 30px;
}

h2 {
	font-size: 26px;
}

h3 {
	font-size: 21px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 14px;
}

h6 {
	font-size: 12px;
}

main {
    height: auto;

    > img {
        align-self: flex-start;
    }
}

.form-control::placeholder
{
	color: #aaa;
}

*[id$='-error'] {
	color: red;
}

.list-oneline {
    padding: 0;
    list-style: none;
    > li {
        display: inline;
        &:not(:last-child):after {
            content: ", ";
        }
    }
}
