/*
 * Copyright (C) 2023 The Hunter2 Contributors.
 *
 * This file is part of Hunter2.
 *
 * Hunter2 is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any later version.
 *
 * Hunter2 is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A
 * PARTICULAR PURPOSE.  See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License along with Hunter2.  If not, see <http://www.gnu.org/licenses/>.
 */

.socialaccount_ballot {
    ul {
        list-style: none;
        padding: 0;
        li {
            margin: 5px 0;
        }
    }
}

span.socialaccount_provider {
    font-size: 0;
    &::before {
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        float: left;
        height: 1.5rem;
        line-height: 0;
        margin: 0.5rem;
        padding: 0;
        width: 1.5rem;
    }
    &.discord {
        &::before {
            background-image: url("accounts/img/discord-black.svg");
        }
    }
    &.openid,&.steam {
        &::before {
            content: url("accounts/img/steam-black.svg");
        }
    }
}

ul.socialaccount_providers {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .socialaccount_provider {
        &::before {
            display: block;
            float: left;
            height: 1.5em;
            line-height: 0;
            margin: 0.5em;
            padding: 0;
            width: 1.5em;
        }
        align-items: center;
        display: flex;
        font-size: 18pt;
        text-decoration: none;
        white-space: nowrap;
        width: 100%;

        &.discord {
            &::before {
                content: url("accounts/img/discord-white.svg");
            }
            background-color: #5865f2;
            color: #ffffff;
        }

        &.steam {
            &::before {
                content: url("accounts/img/steam-white.svg");
            }
            background-color: #000000;
            color: #ffffff;
        }
    }
}
