
h2 {
    font-size: 1.2em;
    display: inline;
    margin-right: 0.5em;
}

p {
    display: inline;
}
